<script lang="ts" setup>
import { testingType } from '../stores'
</script>

<template>
  <div v-show="testingType === 'e2e'" class="mt-[48px]">
    <div class="bg-jade-50/50">
      <slot name="e2e" />
    </div>
  </div>
  <div v-show="testingType === 'component'">
    <div class="bg-[#f5f5ff]">
      <slot name="component" />
    </div>
  </div>
</template>
